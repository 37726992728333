import { Card, CardDeck, Col, Container, Row } from "react-bootstrap"
import { Link, withI18next } from "gatsby-plugin-i18next"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { I18n } from "react-i18next"
import Img from "gatsby-image"
import Layout from "../components/layout"
import React from "react"
import SEO from "../components/seo"
import coop from "../images/icons/cooperative.png"
import { faUserFriends } from "@fortawesome/free-solid-svg-icons"
import { graphql } from "gatsby"
import home from "../images/icons/home.png"
import support from "../images/icons/support.png"
import technical_advisors from "../images/icons/technical_advisors.png"
import training from "../images/icons/training.png"

const McticAbout = ({
  data: { associations, mctic, contentfulStats },
  pageContext,
}) => {
  return (
    <I18n>
      {t => (
        <Layout pageContext={pageContext}>
          <SEO title={t("tuhai")} />
          <div className="section bg-dark-blue mt-4">
            <Container className="height-md-30">
              <Row>
                <Col md="8" lg="7" xl="6">
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb p-0 bg-dark-blue bg-transparent">
                      <li className="breadcrumb-item">
                        <Link to="/" className="text-white">
                          {t("home")}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/mctic-about" className="text-white">
                          {t("about_us")}
                        </Link>
                      </li>
                    </ol>
                  </nav>
                </Col>
                <Col md="12">
                  <h1 className="display-5 text-white">
                    {t("about_us")} <FontAwesomeIcon icon={faUserFriends} />
                  </h1>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="content">
            <Container>
              <Row>
                <Col>
                  <h2>{t("mctic_to_date")}</h2>
                </Col>
              </Row>
            </Container>
            <div className="section bg-white pb-4 pt-0">
              <Container>
                <Row>
                  <CardDeck>
                    <Card border="primary">
                      <img
                        src={support}
                        alt={t("mctic_to_date_desc_1")}
                        className="card-icon"
                      />
                      <Card.Body>
                        <div className="card-title h4">
                          {contentfulStats.stats.support}
                        </div>
                        <Card.Text>{t("mctic_to_date_desc_1")}</Card.Text>
                      </Card.Body>
                    </Card>
                    <Card border="primary">
                      <img
                        src={home}
                        alt={t("mctic_to_date_desc_2")}
                        className="card-icon"
                      />
                      <Card.Body>
                        <div className="card-title h4">
                          {contentfulStats.stats.home}
                        </div>
                        <Card.Text>{t("mctic_to_date_desc_2")}</Card.Text>
                      </Card.Body>
                    </Card>
                    <div className="w-100 d-none d-sm-block d-lg-none"></div>
                    <Card border="primary">
                      <img
                        src={coop}
                        alt={t("mctic_to_date_desc_3")}
                        className="card-icon"
                      />
                      <Card.Body>
                        <div className="card-title h4">
                          {contentfulStats.stats.projects.national +
                            contentfulStats.stats.projects.international}
                        </div>
                        <Card.Text>{t("mctic_to_date_desc_3")}</Card.Text>
                        <Card.Text>
                          {t("mctic_to_date_desc_4")}
                          <span className="h5">
                            - {contentfulStats.stats.projects.national}{" "}
                          </span>
                        </Card.Text>
                        <Card.Text>
                          {t("mctic_to_date_desc_5")}
                          <span className="h5">
                            - {contentfulStats.stats.projects.international}{" "}
                          </span>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                    <Card border="primary">
                      <img
                        src={technical_advisors}
                        alt={t("mctic_to_date_desc_6")}
                        className="card-icon"
                      />
                      <Card.Body>
                        <div className="card-title h4">
                          {contentfulStats.stats.technical_advisors}
                        </div>
                        <Card.Text>{t("mctic_to_date_desc_6")}</Card.Text>
                      </Card.Body>
                    </Card>
                    <Card border="primary">
                      <img
                        src={training}
                        alt={t("mctic_to_date_desc_7")}
                        className="card-icon"
                      />
                      <Card.Body>
                        <div className="card-title h4">
                          {contentfulStats.stats.training}
                        </div>
                        <Card.Text>{t("mctic_to_date_desc_7")}</Card.Text>
                        {/* <Card.Text>
                            <span className="h5">542 </span>
                            {t("mctic_to_date_desc_8")}
                          </Card.Text>
                          <Card.Text>
                            <span className="h5">77 </span>
                            {t("mctic_to_date_desc_9")}
                          </Card.Text>
                          <Card.Text>
                            <span className="h5">9 </span>
                            {t("mctic_to_date_desc_10")}
                          </Card.Text>
                          <Card.Text>
                            <span className="h5">33 </span>
                            {t("mctic_to_date_desc_11")}
                          </Card.Text> */}
                      </Card.Body>
                    </Card>
                  </CardDeck>
                </Row>
              </Container>
            </div>
            <div className="section pb-4 pt-4 mb-0">
              <Container>
                <h2>{t("board_of_directors")}</h2>
                <h5>{t("mctic_members")}:</h5>
                <ul className="mb-5">
                  <li>{t("cumcc_title")}</li>
                  <li>{t("cumic_title")}</li>
                  <li>{t("namac_title")}</li>
                  <li>{t("umpsc_title")}</li>
                  <li>{t("aphc_title")}</li>
                </ul>
                <Row className="team justify-content-md-center">
                  <Col sm="12" md="6" lg="3">
                    <div className="image-frame no_link scale-with-grid">
                      <div className="image-wrapper">
                        <Img
                          fluid={
                            associations.nodes
                              .filter(i => i.name === "Erdene")
                              .pop().childImageSharp.fluid
                          }
                          alt={t("r_erdene")}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6" lg="6">
                    <div className="desc_wrapper">
                      <h4>{t("r_erdene")}</h4>
                      <hr className="hr_color" />
                      <div className="desc">
                        <p>{t("r_erdene_desc_mctic")}</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="team team-list">
                  <Col sm="12" md="6" lg="3">
                    <Card className="">
                      <div className="image-frame">
                        <div className="image-wrapper">
                          <Img
                            fluid={
                              associations.nodes
                                .filter(i => i.name === "Manaljav")
                                .pop().childImageSharp.fluid
                            }
                            className="card-img-top"
                            alt={t("g_manaljav")}
                          />
                        </div>
                      </div>
                      <Card.Body>
                        <Card.Title>{t("g_manaljav")}</Card.Title>
                        <Card.Text>{t("g_manaljav_desc_mctic")}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="12" md="6" lg="3">
                    <Card className="">
                      <div className="image-frame">
                        <div className="image-wrapper">
                          <Img
                            fluid={
                              associations.nodes
                                .filter(i => i.name === "Altangaya")
                                .pop().childImageSharp.fluid
                            }
                            className="card-img-top"
                            alt={t("ch_altangaya")}
                          />
                        </div>
                      </div>
                      <Card.Body>
                        <Card.Title>{t("ch_altangaya")}</Card.Title>
                        <Card.Text>{t("ch_altangaya_desc_mctic")}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="12" md="6" lg="3">
                    <Card className="">
                      <div className="image-frame">
                        <div className="image-wrapper">
                          <Img
                            fluid={
                              associations.nodes
                                .filter(i => i.name === "Altantuya")
                                .pop().childImageSharp.fluid
                            }
                            className="card-img-top"
                            alt={t("ts_altantuya")}
                          />
                        </div>
                      </div>
                      <Card.Body>
                        <Card.Title>{t("ts_altantuya")}</Card.Title>
                        <Card.Text>{t("ts_altantuya_desc_mctic")}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="12" md="6" lg="3">
                    <Card className="">
                      <div className="image-frame">
                        <div className="image-wrapper">
                          <Img
                            fluid={
                              associations.nodes
                                .filter(i => i.name === "Enkhtuya")
                                .pop().childImageSharp.fluid
                            }
                            className="card-img-top"
                            alt={t("s_enkhtuya")}
                          />
                        </div>
                      </div>
                      <Card.Body>
                        <Card.Title>{t("s_enkhtuya")}</Card.Title>
                        <Card.Text>{t("s_enkhtuya_desc_mctic")}</Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="section pb-4 pt-4 mb-0">
              <Container>
                <h2>{t("mctic_team")}</h2>
                <Row className="team justify-content-md-center">
                  <Col sm="12" md="6" lg="3">
                    <div className="image-frame no_link scale-with-grid">
                      <div className="image-wrapper">
                        <Img
                          fluid={
                            mctic.nodes
                              .filter(i => i.name === "myagmar-ochir")
                              .pop().childImageSharp.fluid
                          }
                          className="card-img-top"
                          alt={t("ts_myagmarochir")}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" md="6" lg="6">
                    <div className="desc_wrapper">
                      <h4>{t("ts_myagmarochir")}</h4>
                      <hr className="hr_color" />
                      <div className="desc">
                        <p>{t("ts_myagmarochir_title_1")}</p>
                        <p>{t("ts_myagmarochir_title_2")}</p>
                        <p>myagmarochir@mongolia.coop</p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="team team-list">
                  <Col sm="12" md="6" lg="3" className="d-flex">
                    <Card>
                      <div className="image-frame">
                        <div className="image-wrapper">
                          <Img
                            fluid={
                              mctic.nodes
                                .filter(i => i.name === "enkhtur")
                                .pop().childImageSharp.fluid
                            }
                            className="card-img-top"
                            alt={t("b_enkhtur")}
                          />
                        </div>
                      </div>
                      <Card.Body>
                        <Card.Title>{t("b_enkhtur")}</Card.Title>
                        <Card.Text>{t("b_enkhtur_desc")}</Card.Text>
                        <Card.Text className="email">
                          enkhtur@mongolia.coop
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="12" md="6" lg="3" className="d-flex">
                    <Card>
                      <div className="image-frame">
                        <div className="image-wrapper">
                          <Img
                            fluid={
                              mctic.nodes
                                .filter(i => i.name === "erdenebileg")
                                .pop().childImageSharp.fluid
                            }
                            className="card-img-top"
                            alt={t("u_erdenebileg")}
                          />
                        </div>
                      </div>
                      <Card.Body>
                        <Card.Title>{t("u_erdenebileg")}</Card.Title>
                        <Card.Text>{t("u_erdenebileg_desc")}</Card.Text>
                        <Card.Text className="email">
                          erdenebileg@mongolia.coop
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="12" md="6" lg="3" className="d-flex">
                    <Card>
                      <div className="image-frame">
                        <div className="image-wrapper">
                          <Img
                            fluid={
                              mctic.nodes
                                .filter(i => i.name === "munkhjargal")
                                .pop().childImageSharp.fluid
                            }
                            className="card-img-top"
                            alt={t("a_munkhjargal")}
                          />
                        </div>
                      </div>
                      <Card.Body>
                        <Card.Title>{t("a_munkhjargal")}</Card.Title>
                        <Card.Text>{t("a_munkhjargal_desc")}</Card.Text>
                        <Card.Text className="email">
                          amunkhjargal@mongolia.coop
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm="12" md="6" lg="3" className="d-flex">
                    <Card>
                      <div className="image-frame">
                        <div className="image-wrapper">
                          <Img
                            fluid={
                              mctic.nodes
                                .filter(i => i.name === "gundegmaa")
                                .pop().childImageSharp.fluid
                            }
                            className="card-img-top"
                            alt={t("n_gundegmaa")}
                          />
                        </div>
                      </div>
                      <Card.Body>
                        <Card.Title>{t("n_gundegmaa")}</Card.Title>
                        <Card.Text>{t("n_gundegmaa_desc")}</Card.Text>
                        <Card.Text className="email">
                          gundegmaa@mongolia.coop
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="section pb-4 pt-4 mb-0">
              <Container>
                <h4>
                  <a
                    href="https://docs.google.com/spreadsheets/d/1P3IjfY6Na6sV0GrMH2b-MglVwA927BqBZiLFjcmeFtU/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("list_consultants")}
                  </a>
                </h4>
              </Container>
            </div>
          </div>
        </Layout>
      )}
    </I18n>
  )
}

export default withI18next()(McticAbout)

export const query = graphql`
  query($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
      ...TranslationFragment
    }
    associations: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "associations" }
      }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxHeight: 490, maxWidth: 410, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    mctic: allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "mctic/about" }
      }
    ) {
      nodes {
        name
        childImageSharp {
          fluid(maxHeight: 490, maxWidth: 410, quality: 80) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
    contentfulStats(contentful_id: { eq: "1Wi5BDur6Cn2X2Z2fSwKUl" }) {
      title
      stats {
        support: years_supporting_mongolian_co_operatives
        home: regional_co_operative_training_centers_established
        projects {
          national
          international
        }
        technical_advisors: beneficiaries_reached_in_the_past_20_years
        training: technical_advisors
      }
    }
  }
`
